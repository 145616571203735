
.panels-basic {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-radius: 0 !important;
    ::v-deep {
        .v-expansion-panel {
            margin-top: 0 !important;
            &:not(:first-child) {
                border-top: 1px solid var(--border-color);
            }
        }
        .v-expansion-panel-header {
            padding: 20px 0;
            &__icon .v-icon {
                color: #2f2f2f !important;
            }
            &--active {
                .v-expansion-panel-header {
                    &__icon .v-icon {
                        color: var(--v-primary-base) !important;
                    }
                }
            }
        }
        .v-expansion-panel-content {
            background-color: #f8f8f8;
            &__wrap {
                padding: 20px;
            }
        }
    }
    &__icon-btn {
        width: 30px !important;
        height: 30px !important;
        .v-icon {
            font-size: 20px;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .panels-basic {
        ::v-deep {
            .v-expansion-panel-header {
                padding: 30px 0;
                &__icon .v-icon {
                    font-size: 2.8rem;
                }
            }
            .v-expansion-panel-content {
                &__wrap {
                    padding: 30px 60px;
                }
            }
        }
        &__icon-btn {
            width: 36px !important;
            height: 36px !important;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
